import '../index.css'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Button, Col, Row, Typography } from 'antd'
import dayjs from 'dayjs'
import { isMobile } from 'react-device-detect'
import { CalendarOutlined } from '@ant-design/icons'
import { Bar, Column, ColumnConfig } from '@ant-design/plots'
import { gql, useQuery } from '@apollo/client'
import { defaultEnd, defaultStart, PageProps } from '../'
import PageDrawer from '../../../components/Drawer/DatePickerDrawer'
import { Loading } from '../../../components/Loading/defaultLoading'
import { PaddingBox, PageSubtitle, PageTitle, TabHeader } from '../../../components/Wrapper'
import * as CONFIG from './chartConfig'
import type { KA } from './chartParser'
import { parseReportData, parseReportDataWithType } from './chartParser'

const { Text } = Typography

const QUERY_REPORTS = gql`
  query ($username: String!, $start: String!, $end: String!) {
    physician {
      queryPatient(username: $username) {
        report {
          bloodGlucoseReport(start: $start, end: $end) {
            readingList
            recordDateList
          }
          bloodPressureReport(start: $start, end: $end) {
            heartRateReadingList
            bloodPressureReadingLowerList
            bloodPressureReadingUpperList
            recordDateList
          }
          weightReport(start: $start, end: $end) {
            readingList
            recordDateList
          }
          foodIntakeReport(start: $start, end: $end) {
            carbsPercent
            proteinPercent
            fatPercent
            recordDateList
            caloriesList
          }
          activityReport(start: $start, end: $end) {
            recordDateList
            stepReadingList
            activeMinutesReadingList
            caloriesReadingList
          }
        }
      }
    }
  }
`

/* ------------------------------ card body fc ------------------------------ */

interface ChartCardBodyProps {
  title: string
  description: string
  chartConfig: ColumnConfig
  type?: string
}

const ChartCardBody: React.FC<ChartCardBodyProps> = ({ title, description, chartConfig, type = 'Column' }) => {
  return (
    <>
      <div className='report_chart_container' style={{ padding: isMobile ? 16 : 24 }}>
        <Text style={{ fontSize: '18px' }}>{title}</Text>
        <br />
        <Text type='secondary' style={{ fontSize: '12px' }}>
          {description}
        </Text>
        <br />
        {type === 'Column' && <Column height={350} style={{ marginTop: '16px' }} {...chartConfig} />}
        {type === 'Bar' && <Bar height={350} style={{ marginTop: '16px' }} {...chartConfig} />}
      </div>
    </>
  )
}

/* --------------------------------- main fc -------------------------------- */

const Report: React.FC<PageProps & { setReady: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  userName,
  loginToken,
  setReady,
}) => {
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs(defaultStart, 'YYYY-MM-DD'),
    dayjs(defaultEnd, 'YYYY-MM-DD'),
  ])
  const [drawer, setDrawer] = useState(false)
  const dateString = dateRange[0].format('DD MMM YYYY') + ' - ' + dateRange[1].format('DD MMM YYYY')
  const { loading, data: queryData } = useQuery(QUERY_REPORTS, {
    variables: { username: userName, start: dateRange[0].format('YYYY-MM-DD'), end: dateRange[1].format('YYYY-MM-DD') },
    context: { headers: { Authorization: loginToken } },
  })
  const _span = isMobile ? 24 : 12

  const reportsData = queryData?.physician?.queryPatient?.report || null

  const showDrawer = useCallback(() => {
    setDrawer(true)
  }, [setDrawer])

  const closeDrawer = useCallback(() => {
    setDrawer(false)
  }, [setDrawer])

  useEffect(() => {
    if (!loading) {
      setReady(true)
    }
  }, [loading, setReady])

  return (
    <>
      <PageDrawer
        isOpen={drawer}
        closeDrawer={closeDrawer}
        _defaultStart={defaultStart}
        _defaultEnd={defaultEnd}
        _start={dateRange[0]}
        _end={dateRange[1]}
        setDateRange={setDateRange}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {reportsData && (
            <>
              <TabHeader border>
                <PageTitle text='Reports' />
                {isMobile ? (
                  <>
                    <Button
                      type='primary'
                      onClick={showDrawer}
                      // size='small'
                      icon={<CalendarOutlined style={{ color: '#fff' }} size={14} />}
                    />
                  </>
                ) : (
                  <>
                    <Button type='primary' onClick={showDrawer}>
                      <CalendarOutlined style={{ color: '#fff' }} />
                      {`${dateRange[0].format('DD MMM YYYY')} - ${dateRange[1].format('DD MMM YYYY')}`}
                    </Button>
                  </>
                )}
              </TabHeader>
              <PaddingBox>
                <PageSubtitle text='Data Charts' />
                <Row gutter={[24, 24]}>
                  {reportsData?.bloodGlucoseReport && (
                    <>
                      <Col span={_span}>
                        <ChartCardBody
                          title='Blood Glucose'
                          description={dateString}
                          chartConfig={{
                            ...CONFIG.BloodGlucose,
                            data: parseReportData(
                              reportsData.bloodGlucoseReport,
                              'readingList' as KA,
                              dateRange[0],
                              dateRange[1]
                            ),
                            yAxis: {
                              max: 8,
                              tickInterval: 1,
                            },
                            meta: {
                              ...CONFIG.BloodGlucose.meta,
                              value: { alias: 'mmol/L' },
                            },
                          }}
                        />
                      </Col>
                    </>
                  )}
                  {reportsData?.bloodPressureReport && (
                    <>
                      <Col span={_span}>
                        <ChartCardBody
                          title='Blood Pressure'
                          description={dateString}
                          chartConfig={{
                            ...CONFIG.BloodPressure,
                            data: parseReportDataWithType(
                              reportsData.bloodPressureReport,
                              ['bloodPressureReadingLowerList', 'bloodPressureReadingUpperList'] as KA[],
                              ['lower', 'upper'],
                              dateRange[0],
                              dateRange[1]
                            ),
                          }}
                        />
                      </Col>
                    </>
                  )}
                  {reportsData?.bloodPressureReport && (
                    <>
                      <Col span={_span}>
                        <ChartCardBody
                          title='Heart Rate'
                          description={dateString}
                          chartConfig={{
                            ...CONFIG.BloodGlucose,
                            data: parseReportData(
                              reportsData.bloodPressureReport,
                              'heartRateReadingList' as KA,
                              dateRange[0],
                              dateRange[1]
                            ),
                            meta: {
                              ...CONFIG.BloodGlucose.meta,
                              value: { alias: 'bpm' },
                            },
                          }}
                        />
                      </Col>
                    </>
                  )}
                  {reportsData?.foodIntakeReport && (
                    <>
                      <Col span={_span}>
                        <ChartCardBody
                          title='Food Intake'
                          description={dateString}
                          chartConfig={{
                            ...CONFIG.BloodGlucose,
                            data: parseReportData(
                              reportsData.foodIntakeReport,
                              'caloriesList' as KA,
                              dateRange[0],
                              dateRange[1]
                            ),
                            meta: {
                              ...CONFIG.BloodGlucose.meta,
                              value: { alias: 'Cals' },
                            },
                          }}
                        />
                      </Col>
                    </>
                  )}
                  {reportsData?.weightReport && (
                    <>
                      <Col span={_span}>
                        <ChartCardBody
                          title='Weight'
                          description={dateString}
                          chartConfig={{
                            ...CONFIG.BloodGlucose,
                            data: parseReportData(
                              reportsData.weightReport,
                              'readingList' as KA,
                              dateRange[0],
                              dateRange[1]
                            ),
                            meta: {
                              ...CONFIG.BloodGlucose.meta,
                              value: { alias: 'kg' },
                            },
                          }}
                        />
                      </Col>
                    </>
                  )}
                  {reportsData?.foodIntakeReport && (
                    <>
                      <Col span={_span}>
                        <ChartCardBody
                          title='Nutrition Distribution'
                          description={dateString}
                          chartConfig={{
                            ...CONFIG.NutritionDistribution,
                            data: Object.entries(reportsData.foodIntakeReport)
                              .filter((item) => item[0].indexOf('_') === -1)
                              .filter((item) => item[0].indexOf('Percent') !== -1)
                              .map((item) => ({
                                key: item[0],
                                value: Number(item[1]),
                              })),
                          }}
                          type='Bar'
                        />
                      </Col>
                    </>
                  )}
                  {reportsData?.activityReport && (
                    <>
                      <Col span={_span}>
                        <ChartCardBody
                          title='Steps'
                          description={dateString}
                          chartConfig={{
                            ...CONFIG.BloodGlucose,
                            data: parseReportData(
                              reportsData.activityReport,
                              'stepReadingList' as KA,
                              dateRange[0],
                              dateRange[1]
                            ),
                            meta: {
                              recordDate: {
                                alias: 'Record Date',
                                formatter: (value) => dayjs(value, 'YYYY-MM-DD').format('M/DD'),
                              },
                              value: { alias: 'steps' },
                            },
                          }}
                        />
                      </Col>
                      <Col span={_span}>
                        <ChartCardBody
                          title='Active Mins'
                          description={dateString}
                          chartConfig={{
                            ...CONFIG.BloodGlucose,
                            data: parseReportData(
                              reportsData.activityReport,
                              'activeMinutesReadingList' as KA,
                              dateRange[0],
                              dateRange[1]
                            ),
                            color: '#5AC8FA',
                            meta: {
                              recordDate: {
                                alias: 'Record Date',
                                formatter: (value) => dayjs(value, 'YYYY-MM-DD').format('M/DD'),
                              },
                              value: { alias: 'mins' },
                            },
                          }}
                        />
                      </Col>
                      <Col span={_span}>
                        <ChartCardBody
                          title='Cals Burnt'
                          description={dateString}
                          chartConfig={{
                            ...CONFIG.BloodGlucose,
                            data: parseReportData(
                              reportsData.activityReport,
                              'caloriesReadingList' as KA,
                              dateRange[0],
                              dateRange[1]
                            ),
                            color: '#FF5500',
                            meta: {
                              recordDate: {
                                alias: 'Record Date',
                                formatter: (value) => dayjs(value, 'YYYY-MM-DD').format('M/DD'),
                              },
                              value: { alias: 'cals' },
                            },
                          }}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </PaddingBox>
            </>
          )}
        </>
      )}
    </>
  )
}

export default Report
